import { Card, ButtonStack, Button, VSpacing, Title, Text } from '@hh.ru/magritte-ui';
import { TelegramSolidSize16, ViberSolidSize16, VkSolidSize16 } from '@hh.ru/magritte-ui/service';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ChatBotMessengerTypes } from 'src/models/chatBot';

const TrlKeys = {
    title: 'response.chatBot.title',
    description: 'response.chatBot.description',
};

interface ResponseChatBotProps {
    usedResumeHash: string;
}

const ICONS = {
    [ChatBotMessengerTypes.Telegram]: <TelegramSolidSize16 />,
    [ChatBotMessengerTypes.Vk]: <VkSolidSize16 />,
    [ChatBotMessengerTypes.Viber]: <ViberSolidSize16 />,
} as const;

const ResponseChatBot: TranslatedComponent<ResponseChatBotProps> = ({ trls, usedResumeHash }) => {
    const messengers = useSelectorNonNullable((store) => store.chatBot.messengers);

    return (
        <Card padding={24} borderRadius={24} style="neutral" stretched>
            <Title Element="h3" size="small">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacing default={8} />
            <Text style="secondary" typography="paragraph-2-regular">
                {trls[TrlKeys.description]}
            </Text>
            <VSpacing default={16} />
            <ButtonStack orientation="horizontal">
                {messengers.map(({ type, href }) => (
                    <Button
                        key={type}
                        Element={Link}
                        to={`${href}${usedResumeHash}`}
                        target="_blank"
                        mode="primary"
                        size="small"
                        icon={ICONS[type]}
                        hideLabel
                        aria-label={type}
                    />
                ))}
            </ButtonStack>
        </Card>
    );
};

export default translation(ResponseChatBot);
